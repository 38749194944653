import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyGroup = styled.div`
  margin-top: 10%;
  @media (max-width: 640px) {
    margin-top: 18%;
  }
`

const PrivacyParagraph = styled.p`
  font-size: 15px;
  font-weight: 500;
  margin-left: 5%;
  margin-right: 5%;
`

const PrivacyList = styled.ul`
  margin-left: 5%;
  font-size: 15px;
  font-weight: 500;
`

const PrivacyParagraphSubTitle = styled.p`
  margin-top: 1%;
  font-size: 14px;
  font-weight: 700;
  color: black;
  margin-left: 5%;
  margin-right: 5%;
`

const PrivacyParagraphTitle = styled.p`
  margin-top: 1%;
  font-size: 16px;
  font-weight: 800;
  color: black;
  margin-left: 5%;
  margin-right: 5%;
`

const Privacy = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    {/* <PrivacyPolicy /> */}
    <PrivacyGroup>
      <PrivacyParagraphTitle>Privacy Policy:</PrivacyParagraphTitle>
      <PrivacyParagraph>
        Effective date: March 27, 2019
        <br />
        Qriousco ("us", "we", or "our") operates the qriouscotech.com website
        (the "Service"). This page informs you of our policies regarding the
        collection, use, and disclosure of personal data when you use our
        Service and the choices you have associated with that data. Our Privacy
        Policy for Qriousco is created with the help of the &nbsp;
        <a
          href="https://www.freeprivacypolicy.com/free-privacy-policy-generator.php"        >
          Free Privacy Policy
        </a>
        &nbsp; Generator. We use your data to provide and improve the Service.
        By using the Service, you agree to the collection and use of information
        in accordance with this policy. Unless otherwise defined in this Privacy
        Policy, terms used in this Privacy Policy have the same meanings as in
        our Terms and Conditions, accessible from qriouscotech.com
      </PrivacyParagraph>
      <PrivacyParagraphTitle>
        Information Collection and Use:
      </PrivacyParagraphTitle>
      <PrivacyParagraph>
        We collect several different types of information for various purposes
        to provide and improve our Service to you.
        <br />
      </PrivacyParagraph>
      <PrivacyParagraphTitle>Types of Data Collected:</PrivacyParagraphTitle>
      <PrivacyParagraphSubTitle>Personal Data:</PrivacyParagraphSubTitle>
      <PrivacyParagraph>
        While using our Service, we may ask you to provide us with certain
        personally identifiable information that can be used to contact or
        identify you ("Personal Data"). Personally identifiable information may
        include, but is not limited to:
      </PrivacyParagraph>
      <PrivacyList>
        <li>Email address</li>
        <li>First name and last name</li>
        <li>Cookies and Usage Data</li>
      </PrivacyList>
      <PrivacyParagraphSubTitle>Usage Data:</PrivacyParagraphSubTitle>
      <PrivacyParagraph>
        We may also collect information how the Service is accessed and used
        ("Usage Data"). This Usage Data may include information such as your
        computer's Internet Protocol address (e.g. IP address), browser type,
        browser version, the pages of our Service that you visit, the time and
        date of your visit, the time spent on those pages, unique device
        identifiers and other diagnostic data.
      </PrivacyParagraph>
      <PrivacyParagraphSubTitle>
        Tracking & Cookies Data:
      </PrivacyParagraphSubTitle>
      <PrivacyParagraph>
        We use cookies and similar tracking technologies to track the activity
        on our Service and hold certain information. Cookies are files with
        small amount of data which may include an anonymous unique identifier.
        Cookies are sent to your browser from a website and stored on your
        device. Tracking technologies also used are beacons, tags, and scripts
        to collect and track information and to improve and analyze our Service.
        You can instruct your browser to refuse all cookies or to indicate when
        a cookie is being sent. However, if you do not accept cookies, you may
        not be able to use some portions of our Service.
        <br />
        Examples of Cookies we use:
      </PrivacyParagraph>
      <PrivacyList>
        <li>
          <strong>Session Cookies.</strong>We use Session Cookies to operate our
          Service.
        </li>
        <li>
          <strong>Preference Cookies.</strong>We use Preference Cookies to
          remember your preferences and various settings.
        </li>
        <li>
          <strong>Security Cookies.</strong> We use Security Cookies for
          security purposes.
        </li>
      </PrivacyList>
      <PrivacyParagraphTitle>Use Of Data:</PrivacyParagraphTitle>
      <PrivacyParagraph>
        Qriousco uses the collected data for various purposes:
      </PrivacyParagraph>
      <PrivacyList>
        <li>To provide and maintain the Service</li>
        <li>To notify you about changes to our Service</li>
        <li>
          To allow you to participate in interactive features of our Service
          when you choose to do so
        </li>
        <li>To provide customer care and support</li>
        <li>
          To provide analysis or valuable information so that we can improve the
          Service
        </li>
        <li>To monitor the usage of the Service</li>
        <li>To detect, prevent and address technical issues</li>
      </PrivacyList>
      <PrivacyParagraphTitle>Transfer Of Data:</PrivacyParagraphTitle>
      <PrivacyList>
        <li>To comply with a legal obligation</li>
        <li>To protect and defend the rights or property of Qriousco</li>
        <li>
          To prevent or investigate possible wrongdoing in connection with the
          Service
        </li>
        <li>
          To protect the personal safety of users of the Service or the public
        </li>
        <li>To protect against legal liability</li>
      </PrivacyList>
      <PrivacyParagraphTitle>Security Of Data:</PrivacyParagraphTitle>
      <PrivacyParagraph>
        The security of your data is important to us, but remember that no
        method of transmission over the Internet, or method of electronic
        storage is 100% secure. While we strive to use commercially acceptable
        means to protect your Personal Data, we cannot guarantee its absolute
        security.
      </PrivacyParagraph>
      <PrivacyParagraphTitle>Service Providers :</PrivacyParagraphTitle>
      <PrivacyParagraph>
        We may employ third party companies and individuals to facilitate our
        Service ("Service Providers"), to provide the Service on our behalf, to
        perform Service-related services or to assist us in analyzing how our
        Service is used. These third parties have access to your Personal Data
        only to perform these tasks on our behalf and are obligated not to
        disclose or use it for any other purpose.
      </PrivacyParagraph>
      <PrivacyParagraphTitle>Links to Other Sites:</PrivacyParagraphTitle>
      <PrivacyParagraph>
        Our Service may contain links to other sites that are not operated by
        us. If you click on a third party link, you will be directed to that
        third party's site. We strongly advise you to review the Privacy Policy
        of every site you visit. We have no control over and assume no
        responsibility for the content, privacy policies or practices of any
        third party sites or services.
      </PrivacyParagraph>
      <PrivacyParagraphTitle>Children’s Privacy:</PrivacyParagraphTitle>
      <PrivacyParagraph>
        Our Service does not address anyone under the age of 18 ("Children").{" "}
        <br />
        We do not knowingly collect personally identifiable information from
        anyone under the age of 18. If you are a parent or guardian and you are
        aware that your Children has provided us with Personal Data, please
        contact us. If we become aware that we have collected Personal Data from
        children without verification of parental consent, we take steps to
        remove that information from our servers.
      </PrivacyParagraph>
      <PrivacyParagraphTitle>
        Changes to This Privacy Policy:
      </PrivacyParagraphTitle>
      <PrivacyParagraph>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page. We will
        let you know via email and/or a prominent notice on our Service, prior
        to the change becoming effective and update the "effective date" at the
        top of this Privacy Policy. You are advised to review this Privacy
        Policy periodically for any changes. Changes to this Privacy Policy are
        effective when they are posted on this page.
      </PrivacyParagraph>
      <PrivacyParagraphTitle>Contact Us:</PrivacyParagraphTitle>
      <PrivacyParagraph>
        If you have any questions or suggestions about my Privacy Policy, do not
        hesitate to contact us at info.qriousco@gmail.com.
      </PrivacyParagraph>
    </PrivacyGroup>
  </Layout>
)

export default Privacy
